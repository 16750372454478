import React, { Component } from "react";
import AppBar from "components/AppBar";
import { fetchListingData } from "api/contractorcard";
import { BsUpload } from "react-icons/bs";
import { columns } from "data/contractorCard";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDCard from "components/form/contractorcard/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { allCardstatus } from "config";
import { setCookie } from "helpers";
import { createAccess, updateAccess, uploadAccess, debounce } from "helpers";
import CRUDUpload from "components/form/contractorcard/CRUDUpload";

class Contractorcard extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      rowData: undefined,
      formOpen: false,
      skeleton: true,
      upload: false,
      total_entries: 0,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      status: allCardstatus,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = async () => {
    await fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status =
      filters.status && filters.status.length > 0
        ? filters.status
        : allCardstatus;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  onRowClick = (data) => {
    let access = this.updateAction();
    let vehicle_entry_id = data.vehicle_entry_id,
      number_plate = data.number_plate;

    if (access && number_plate) {
      setCookie("vehicle_entry_id", vehicle_entry_id);
      this.props.history.push({
        pathname: `/vehicles/${number_plate}`,
      });
    }
  };

  createAction = () => {
    return createAccess("contractor_cards");
  };

  updateAction = () => {
    return updateAccess("contractor_cards");
  };

  uploadAction = () => {
    return uploadAccess("contractor_cards");
  };

  openEditForm = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  render() {
    const { data, rowData, formOpen, skeleton, upload } = this.state;
    const {
      handleClose,
      handleSubmitSuccess,
      onChange,
      onRowClick,
      openEditForm,
    } = this;
    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      open: formOpen,
      handleSubmitSuccess,
      tabColor: "info",
    };
    const tableProps = {
      normalIcon: <BsUpload />,
      tabColor: "info",
      title: "Visitor Pass",
      subTitle: "visitor pass details are listed here",
      data: data,
      pagination: this.pagination(),
      columns: columns(openEditForm),

      handleTableChange: this.handleTableChange,
      openEditForm: (row) => {
        this.setState({ formOpen: true, rowData: row });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      dataValue: "ADD Card",
      onRowClick,
      createAction: this.createAction(),
      dataValueUpload: "Upload",
      uploadAction: this.uploadAction(),
      skeleton,
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    const CRUDContractorCardUpload = {
      open: upload,
      titleText: "Contractor Card Data",
      onCancel: handleClose,
      handleSubmitSuccess,
      upload: true,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDCard {...CRUDProps} />
        <CRUDUpload {...CRUDContractorCardUpload} />
      </React.Fragment>
    );
  }
}

export default Contractorcard;
