import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchEmployeesData } from "api/employees";
import { columns } from "data/employees";
import CRUDResident from "components/form/employees/CRUD";
import CRUDUpload from "components/form/employees/CRUDUpload";
import { Row } from "antd";
import { createAccess, updateAccess, debounce, uploadAccess } from "helpers";
import { handleResponse } from "components/notifications/handleResponse";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      skeleton: true,
      upload: false,
    };
  }
  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount() {
    this.fetchTableData();
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    this.flushTimer();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchEmployeesData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState({ ...this.state });
    this.debounceSearch();
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  editEmployee = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  handlePaginationChange = (page, pageSize) => {
    this.requestParams.page = page;
    this.requestParams.perPage = pageSize;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("employees");
  };

  updateAction = () => {
    return updateAccess("employees");
  };

  uploadAction = () => {
    return uploadAccess("employees");
  };

  render() {
    const { data, formOpen, rowData, upload, skeleton } = this.state;
    const {
      onChange,
      onCancel,
      handleSubmitSuccess,
      handlePaginationChange,
    } = this;

    const appBarProps = {
      search: this.requestParams.search,
      // searchOpen: true,
      onChange,
    };
    const tableProps = {
      tabColor: "warning",
      title: "Employees",
      subTitle: "Registered Employees",
      columns: columns(this.editEmployee, this.updateAction, {
        data: data,
        pagination: this.pagination(),
      }),
      data: data,
      pagination: this.pagination(),
      onRowClick: () => {},
      handleTableChange: this.handleTableChange,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      dataValue: "ADD Employees",
      dataValueUpload: "Upload",
      skeleton,
      handlePaginationChange,
      createAction: this.createAction(),
      uploadAction: this.uploadAction(),
    };

    const CRUDEmployeeProps = {
      open: formOpen,
      tabColor: "warning",
      titleText: "Employees",
      data: rowData,
      handleSubmitSuccess,
      onCancel,
      // showDelete: true,
      dateFilter: true,
    };

    const CRUDEmployeesUploadProps = {
      open: upload,
      titleText: "Employees Data",
      onCancel,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDResident {...CRUDEmployeeProps} />
        <CRUDUpload {...CRUDEmployeesUploadProps} />
      </React.Fragment>
    );
  }
}
export default Employees;
