import React from "react";
import { Button } from "antd";
import { presence } from "helpers";

export const columns = (editEmployee, updateAction) => {
  return [
    {
      title: "Name",
      align: "center",
      width: "25%",
      render: (row) => {
        return <p style={{ fontWeight: 600 }}>{presence(row.name)}</p>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "Company Name",
      dataIndex: "company",
      render: (company) => <p>{presence(company?.name)}</p>,
      align: "center",
    },
    // {
    //   title: "AccessCard",
    //   render: (row) => {
    //     if (row.access_cards.length > 0)
    //       return row.access_cards.map((card, i) => (
    //         <p key={{ i }}>{presence(card.rfid)}</p>
    //       ));
    //     else return <p>-</p>;
    //   },
    //   align: "center",
    // },
    {
      title: "Manage",
      render: (row) => {
        return (
          <React.Fragment>
            {updateAction() && (
              <Button
                onClick={() => editEmployee(row)}
                style={{
                  margin: "0px 10px 5px 10px",
                }}
              >
                Edit
              </Button>
            )}
          </React.Fragment>
        );
      },
      align: "center",
    },
  ];
};
