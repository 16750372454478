import makeRequest from ".";

export function fetchTiles(display, trend) {
  display = display.map((val) => `&display[]=${val}`).join("");
  trend = trend.map((val) => `&trend[]=${val}`).join("");

  return makeRequest({
    uri: `/api/v1/vehicle_entries/tiles?${display}${trend}`,
  });
}

export function vehicleEntry() {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/type_of_entries`,
  });
}

export function visitorTypes() {
  return makeRequest({
    uri: `/api/v1/visits/visitor_types`,
  });
}

export function natureOfVisitors() {
  return makeRequest({
    uri: `/api/v1/visitors/nature_of_visitors`,
  });
}

export function trafficCamera() {
  return makeRequest({
    uri: `/api/v1/cameras?&type=LnprCamera`,
  });
}

export function trafficData(duration) {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/traffic_flow?duration=${duration}`,
  });
}

export function vehicleExit() {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/recent?camera_type[]=visitor_exit_cam&limit=5`,
  });
}

export function vehicleIn() {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/recent?camera_type[]=resident_entry_cam&camera_type[]=visitor_entry_cam&limit=5`,
  });
}

export function vehicleInResident() {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/recent?camera_type[]=resident_entry_cam&limit=5`,
  });
}

export function vehicleInVisitor() {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/recent?camera_type[]=visitor_entry_cam&limit=5`,
  });
}

export function visitorIn() {
  return makeRequest({
    uri: `/api/v1/visits/recent?visitor_type[]=guest&per_page=5`,
  });
}

export function vehicleInPickUp() {
  return makeRequest({
    uri: `/api/v1/visits/recent?visitor_type[]=pickup/drop&per_page=5`,
  });
}

export function vehicleInDelivery() {
  return makeRequest({
    uri: `/api/v1/visits/recent?visitor_type[]=delivery&per_page=5`,
  });
}

export function vehicleInContractor() {
  return makeRequest({
    uri: `/api/v1/visits/recent?visitor_type[]=contractor&per_page=5`,
  });
}

export function alertsRaised() {
  return makeRequest({
    uri: `/api/v1/alerts?status[]=raised&per_page=5`,
  });
}
