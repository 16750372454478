import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton";

function Form(props) {
  const { handleChange, card_id,card_name, status, id } = props;
  const contractType = {
    labelText: "Status",
    value: status,
    data: ["assigned", "available", "lost"],
    onChange: handleChange,
    name: "status",
    width: "32%",
  };
  return (
    <React.Fragment>
      <Row>
      <Col sm={8} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Pass Name"
              name="card_name"
              required
              value={card_name}
              onChange={handleChange}
              readOnly={!!id}
            />
          </Col>
          <Col sm={16} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Pass ID"
              name="card_id"
              required
              value={card_id}
              onChange={handleChange}
              readOnly={!!id}
            />
          </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          style={{ padding: "20px 10px", textAlign: "center" }}
        >
          <RadioButton {...contractType} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Form;
