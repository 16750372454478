import React from "react";
import Charts from "components/charts/dashboardCharts";
import VehicleInOut from "views/Home/Sections/VehicleInOut";
import VisitorInOut from 'views/Home/Sections/visitorInOut'
import {
  FaTruckPickup,
  FaCarAlt,
  FaCar,
  FaCarSide,
  FaPersonBooth,
} from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import { GiDoorHandle, GiCryoChamber } from "react-icons/gi";
import { MdSupervisorAccount, MdTimeline } from "react-icons/md";
import {
  vehicleEntry,
  visitorTypes,
  natureOfVisitors,
  vehicleExit,
  vehicleIn,
  vehicleInResident,
  vehicleInVisitor,
  vehicleInPickUp,
  vehicleInDelivery,
  vehicleInContractor,
  alertsRaised,
  visitorIn
} from "api/dashboard";
import { columns as vehicleEntryColumns } from "data/vehicleInVisitor";
import { columns as vehicleExitColumns } from "data/vehicleExit";
import { columns as alertsColumn } from "data/alertsRaised";
import { pickupDeliveryColumns, contractorColumns } from "data/vehicleTypes";

export const TypeOfEntry = () => {
  return (
    <Charts
      bindto="type-of-entries"
      fetchFun={vehicleEntry}
      title="Types Of Entries"
      subtitle="Last 24 hours"
      icon={<FaPersonBooth />}
      chartType="donut"
    />
  );
};

export const VisitorType = () => {
  return (
    <Charts
      bindto="type-of-visitors"
      fetchFun={visitorTypes}
      title="Types Of Visitors"
      subtitle="Last 24 hours"
      icon={<MdTimeline />}
      chartType="pie"
    />
  );
};

export const NatureOfVisitor = () => {
  return (
    <Charts
      bindto="nature-of-visitors"
      fetchFun={natureOfVisitors}
      title="Nature Of Visitors"
      subtitle="Last 24 hours"
      icon={<MdSupervisorAccount />}
      chartType="donut"
    />
  );
};

export const JustEnteredResident = () => {
  return (
    <VehicleInOut
      title="Just Entered"
      type="(Resident)"
      subtitle="Recent 5 Entries"
      color="primary"
      icon={<FaCar />}
      fetchUrl={vehicleInResident}
      columns={vehicleEntryColumns}
      titleColor={"#964CAE"}
    />
  );
};

export const JustEnteredVisitor = () => {
  return (
    <VehicleInOut
      title="Just Visited"
      type="(Visitor)"
      subtitle="Recent 5 Entries"
      color="primary"
      icon={<FaCarAlt />}
      fetchUrl={vehicleInVisitor}
      columns={vehicleEntryColumns}
      titleColor={"#964CAE"}
    />
  );
};

export const JustEntered = () => {
  return (
    <VehicleInOut
      title="Just Entered"
      subtitle="Recent 5 Entries"
      color="primary"
      icon={<FaCar />}
      fetchUrl={vehicleIn}
      columns={vehicleEntryColumns}
      titleColor={"#964CAE"}
    />
  );
};

export const JustExited = () => {
  return (
    <VehicleInOut
      title="Just Exited"
      subtitle="Recent 5 Entries"
      color="warning"
      icon={<FaCarSide />}
      fetchUrl={vehicleExit}
      columns={vehicleExitColumns}
      titleColor={"#fb8c00"}
    />
  );
};

export const RaisedAlerts = () => {
  return (
    <VehicleInOut
      title="Alerts Raised"
      subtitle="Recent 5 Raised Alerts"
      color="danger"
      icon={<IoMdAlert />}
      fetchUrl={alertsRaised}
      columns={alertsColumn}
      titleColor={"red"}
    />
  );
};

export const JustVisitedContractor = () => {
  return (
    <VehicleInOut
      title="Just Visited"
      type="(Contractor)"
      subtitle="Recent 5 Entries"
      color="success"
      icon={<GiDoorHandle />}
      fetchUrl={vehicleInContractor}
      columns={contractorColumns}
      titleColor={"#8e24aa"}
    />
  );
};

export const JustVisited = () => {
  return (
    <VisitorInOut
      title="Just Visited"
      type="(Visitor)"
      subtitle="Recent 5 Entries"
      color="primary"
      icon={<MdSupervisorAccount />}
      fetchUrl={visitorIn}
      columns={contractorColumns}
      titleColor={"#8e24aa"}
    />
  );
};


export const JustVisitedPickupDrop = () => {
  return (
    <VehicleInOut
      title="Just Visited"
      type="(Pickup/Drop)"
      subtitle="Recent 5 Entries"
      color="warning"
      icon={<FaTruckPickup />}
      fetchUrl={vehicleInPickUp}
      columns={pickupDeliveryColumns}
      titleColor={"#fb8c00"}
    />
  );
};

export const JustVisitedDelivery = () => {
  return (
    <VehicleInOut
      title="Just Visited"
      type="(Delivery)"
      subtitle="Recent 5 Entries"
      color="primary"
      icon={<GiCryoChamber />}
      fetchUrl={vehicleInDelivery}
      columns={pickupDeliveryColumns}
      titleColor={"#8e24aa"}
    />
  );
};
