import React from "react";
import { presence, titleize } from "helpers";
import { Button } from "antd";
import { allCardstatus } from "config";

export const columns = (openEditForm) => {
  return [
    {
      title: "Pass Name / ID",
      align: "center",
      width: "35%",
      render: (row) => {
        return (
          <React.Fragment>
            <p style={{ margin: 0, fontWeight: 600 }}>
              {presence(row.card_name)}
            </p>
            <p style={{ margin: 0 }}>{presence(row.card_id)}</p>
          </React.Fragment>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => `${titleize(status)}`,
      filters:
        allCardstatus &&
        allCardstatus.map((type) => {
          return { text: titleize(type), value: type };
        }),
    },
    {
      title: "Name / Phone",
      dataIndex: "current_assignee",
      width: "35%",
      align: "center",
      render: (current_assignee) => {
        let name = `${titleize(current_assignee?.visitor?.name || "")}`;
        let phone = `${titleize(current_assignee?.visitor?.phone || "")}`;
        return (
          <React.Fragment>
            <p style={{ margin: 0 }}>{presence(name)}</p>
            <p style={{ margin: 0, fontWeight: 600 }}>{presence(phone)}</p>
          </React.Fragment>
        );
      },
    },
    {
      title: "Actions",
      render: (row) => {
        return <Button onClick={() => openEditForm(row)}>Edit</Button>;
      },
      align: "center",
    },
  ];
};
