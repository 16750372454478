import React, { Component } from "react";
import { Row } from "antd";
import PaginationTable from "components/paginationTables/withAddEdit";
import AppBar from "components/AppBar";
import { BsUpload } from "react-icons/bs";
import { createAccess, uploadAccess, updateAccess, debounce } from "helpers";
import { Columns } from "data/company";
import { companyData } from "api/company";
import { handleResponse } from "components/notifications/handleResponse";
import CRUDCompany from "components/form/company/CRUD";
import CRUDUpload from "components/form/company/CRUDUpload";
import moment from "moment";

class Company extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      upload: false,
      skeleton: true,
      loader: false,
    };
  }
  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("day")).format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 10000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  createAction = () => {
    return createAccess("companies");
  };

  updateAction = () => {
    return updateAccess("companies");
  };

  fetchTableData = () => {
    companyData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data,
          total_entries,
          skeleton: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      upload: false,
      rowData: undefined,
    });
  };
  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };
  componentDidMount() {
    this.fetchTableData();
  }

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  uploadAction = () => {
    return uploadAccess("companies");
  };

  aboutCompany = (row) => {
    if (row.id) {
      this.props.history.push({
        pathname: `/companies/${row.id}`,
        state: {
          row,
        },
      });
    }
  };
  editAction = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  render() {
    const { data, formOpen, rowData, upload, skeleton, loader } = this.state;
    const {
      handleTableChange,
      onCancel,
      handleClose,
      handleSubmitSuccess,
      onChange,
      editAction,
      aboutCompany,
    } = this;
    const tableProps = {
      tabColor: "info",
      normalIcon: <BsUpload />,
      title: "Company",
      subTitle: "List of Companies in ST Logistics",
      data,
      columns: Columns(editAction, aboutCompany),
      skeleton,
      loader,
      pagination: this.pagination(),
      handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      dataValue: "ADD Company",
      dataValueUpload: "Upload",
      createAction: this.createAction(),
      uploadAction: this.uploadAction(),
      updateAction: this.updateAction(),
      aboutCompany: aboutCompany,
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    const CRUDCompanyProps = {
      data: rowData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "info",
      onChange,
    };

    const CRUDCompanyUploadProps = {
      open: upload,
      titleText: "Company Data",
      onCancel,
      handleSubmitSuccess,
      upload: true,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDCompany {...CRUDCompanyProps} />
        <CRUDUpload {...CRUDCompanyUploadProps} />
      </React.Fragment>
    );
  }
}

export default Company;
