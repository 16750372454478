import React, { Component } from "react";
import { createEmployee, updateEmployee } from "api/employees";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
import { companyData } from "api/company";
import { createVehicle } from "api/vehicles";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.id = undefined;
    this.access = {
      rfid: "",
      status: "active",
    };
    this.state = {
      open: false,
      companylist: [],
      data: {
        name: "",
        phone: "",
        status: "active",
        tabActiveKey: "Details",
        vehicles: [],
        company: [],
        // access_cards: [{ ...this.access }],
      },
    };
  }

  handleChange = (event, index) => {
    const { data } = this.state;
    if (index !== undefined) {
      this.setState({ data });
    } else {
      data[event.target.name] = event.target.value;
      this.setState({ data });
    }
  };

  // rfidChange = (event, index) => {
  //   let card = this.state.data.access_cards;
  //   if (index === card.length - 1) {
  //     if (!card[index].rfid) {
  //       card.push({
  //         rfid: "",
  //         status: "active",
  //       });
  //     }
  //   }
  //   card[index][event.target.name] = event.target.value;
  //   if (card[index].rfid) {
  //     this.setState({ access_cards: card });
  //   } else {
  //     card.length > 1 && card.pop();
  //     this.setState({ access_cards: card });
  //   }
  // };

  onSubmit = () => {
    const { id, props } = this;
    const { data } = this.state;
    // let rfid_cards = [];

    // data.access_cards &&
    //   data.access_cards.forEach((card) => {
    //     if (card.rfid.length !== 0) {
    //       rfid_cards.push(card);
    //     }
    //   });



    const formData = new FormData();

    formData.append("employees[name]", data.name);
    formData.append("employees[phone]", data.phone);
    formData.append("employees[company_id]", data.company?.value || null);
    data.vehicles &&
      data.vehicles.map((vehicle) => {
        return formData.append("employees[number_plate][]", vehicle.value);
      });
    formData.append("employees[status]", data.status);
    // rfid_cards.forEach((id) => {
    //   formData.append("employees[access_point_ids][]", id);
    // });
    // formData.append("employees[access_cards][]", rfid_cards);

    props.submitClick();
    const method = !id ? createEmployee : updateEmployee;
    method(formData, id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    // let access_card = this.props?.data?.access_cards || [];
    // let temp = access_card && access_card.filter((c) => c.rfid !== "");
    // temp.push(this.access);

    if (this.props.data) {
      const { id, name, phone, status, company, vehicles } = this.props.data;
      this.id = id;
      let props_vehicles = vehicles?.map(obj => ({ label: obj?.number_plate, value: obj?.id }))
      this.setState({
        data: {
          ...this.state.data,
          name,
          phone,
          status,
          // access_cards: temp,
          vehicles: props_vehicles,
          company: { label: company?.name, value: company?.id }
        },
      });
    }
    companyData().then(({ data }) => {
      let list = []
      data.map((value) => (
        list.push({ label: value.name, value: value.id })
      ))
      this.setState({ companylist: list })
    })
      .catch((err) => {
        console.log(err);
      })

    // searchUnitData({ search: unit_number }).then(val => {
    //   let units = val.data.map(value => ({
    //     label: value.unit_number,
    //     value: value.id
    //   }));
    //   const { data } = this.state;
    //   data["unit_number"] = units;
    //   this.setState({ data });
    // });
  };


  handleCreateVehicles = (value) => {
    let vehicles = this.state.data.vehicles ? [...this.state.data.vehicles] : [];
    createVehicle({ vehicles: { number_plate: value } })
      .then(({ data }) => {
        vehicles.push({
          value: data.id,
          label: data.number_plate,
        });
        this.setState({ data: { ...this.state.data, vehicles } });
      })
      .catch((error) => {
        // handleResponse(error);
        console.log(error);
      });
  };

  onDelete = () => {
    this.setState({ open: true });
  };

  cancelDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleChange, onCreate,
      // rfidChange,
      addNew, handleCreateVehicles } = this;
    const { dateFilter } = this.props;
    const {
      data: { ...rest },
      companylist,
    } = this.state;

    const EmployeeProps = {
      dateFilter,
      ...rest,
      handleChange,
      handleCreate: onCreate,
      // rfidChange,
      id: this.id,
      addNew,
      companylist,
      handleCreateVehicles
    };
    return <Form {...EmployeeProps} />;
  }
}
export default DialogForm()(CRUD);
